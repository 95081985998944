import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useCart } from '../../context/cartContext';
import { message, Button, Input } from 'antd'; // Importar Input de Ant Design

const ProductList = () => {
  const { addItemToCart } = useCart();
  const [products, setProducts] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]); // Estado para los productos filtrados
  const [orderBy, setOrderBy] = useState('alphabetical');
  const [searchTerm, setSearchTerm] = useState(''); // Estado para el término de búsqueda
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const fetchProducts = async () => {
      let { data: products, error } = await supabase
        .from('products')
        .select('*');
      if (error) {
        console.error('Error fetching products:', error);
      } else {
        setProducts(products);
        const sortedProducts = products.sort((a, b) => a.name.localeCompare(b.name));
        setSortedProducts(sortedProducts);
        setFilteredProducts(sortedProducts); // Inicialmente muestra todos los productos ordenados
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    let sortedArray = [...products];
    
    if (orderBy === 'availability') {
      // Ordenar por disponibilidad
      sortedArray = sortedArray.sort((a, b) => b.pieces - a.pieces);
    } else if (orderBy === 'alphabetical') {
      // Ordenar alfabéticamente
      sortedArray = sortedArray.sort((a, b) => a.name.localeCompare(b.name));
    }  else if (orderBy === 'lower-price') {
        // Ordenar por precio de manera numérica
        sortedArray = sortedArray.sort((a, b) => a.price - b.price); 
      }  else if (orderBy === 'highest-price') {
        // Ordenar por precio de manera numérica
        sortedArray = sortedArray.sort((a, b) => b.price - a.price); 
    }
    // Filtra los productos por el término de búsqueda
    const filtered = sortedArray.filter(product => 
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredProducts(filtered);
  }, [orderBy, products, searchTerm]);

  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Actualiza el término de búsqueda a medida que el usuario escribe
  };

  const handleAddToCart = (product, quantity) => {
    if (product.pieces > 0) {
      addItemToCart(product, quantity);
      messageApi.info(`${product.name} ha sido añadido al carrito!`);
    } else {
      messageApi.warning(`${product.name} está agotado.`);
    }
  };

  const getStatusLabel = (pieces) => {
    if (pieces >= 10) {
      return <div className='product-card-dispo available'>Disponible</div>;
    } else if (pieces > 0 && pieces < 10) {
      return <div className='product-card-dispo limited'>Casi agotado</div>;
    } else {
      return <div className='product-card-dispo sold-out'>Agotado</div>;
    }
  };

  return (
    <>
    <div className="f-h wrap-noreverse center-mobile">
      {/* Campo de búsqueda */}
      <div className="search-bar txt-align-center mb-32">
        <Input 
          placeholder="Buscar productos..." 
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: '350px' }} 
        />
      </div>
      {contextHolder}
      <div className="filter-orders txt-align-center mb-32">
        <label>Ordenar por: </label>
        <select value={orderBy} onChange={handleOrderChange}>
          <option value="alphabetical">Orden alfabético</option>
          <option value="availability">Disponibilidad</option>
          <option value="lower-price">Menor precio</option>
          <option value="highest-price">Mayor Precio</option>
        </select>
      </div>
    </div>

      {/* <ul className='products-wrapper'>
        {filteredProducts.map(product => (
          <li 
            className='product-card' 
            key={product.id}
            style={{ cursor: product.pieces === 0 ? 'not-allowed' : 'pointer' }} 
          >
            {getStatusLabel(product.pieces)}
            <h2>{product.name}</h2>
            <div className='product-image'>
              {product.image_url && <img src={product.image_url} alt={product.name} />}
            </div>
            <p>{product.description}</p>
            <p className='product-price'>${product.price}</p>
            <Button 
              className='btn br-8' 
              onClick={() => handleAddToCart(product, 1)}
              disabled={product.pieces === 0}
            >
              Añadir al carrito
            </Button>
          </li>
        ))}
      </ul> */}
      <ul className='products-wrapper'>
  {filteredProducts.map(product => (
    <li 
      className={`product-card ${product.pieces === 0 ? 'hide' : ''}`} 
      key={product.id}
      style={{ cursor: product.pieces === 0 ? 'not-allowed' : 'pointer' }} 
    >
      {getStatusLabel(product.pieces)}
      <h2>{product.name}</h2>
      <div className='product-image'>
        {product.image_url && <img src={product.image_url} alt={product.name} />}
      </div>
      <p>{product.description}</p>
      <p className='product-price'>${product.price}</p>
      <Button 
        className='btn br-8' 
        onClick={() => handleAddToCart(product, 1)}
        disabled={product.pieces === 0}
      >
        Añadir al carrito
      </Button>
    </li>
  ))}
</ul>
    </>
  );
};

export default ProductList;