// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useAuth } from '../../context/authContext';

// const OrderPlaced = () => {
//     const { state } = useLocation();
//     const navigate = useNavigate();
//     const { user } = useAuth();


//     if (!state || !state.orderDetails) {
//         return <p>Algo salió mal. No se han recibido los detalles del pedido.</p>;
//     }

//     const { orderDetails, formattedDeliveryDate } = state;

//     const handleGoHome = () => {
//         navigate('/');
//     };
// console.log(orderDetails)
//     return (
//         <section>
//         <div className="container mt-20 mb-75">
//             <div className="order-placed-container card-white">
//                 <div className='f-h jc-c a-c column gap-16 mb-16'>
//                     <div class="success-checkmark">
//                         <div class="check-icon">
//                             <span class="icon-line line-tip"></span>
//                             <span class="icon-line line-long"></span>
//                             <div class="icon-circle"></div>
//                             <div class="icon-fix"></div>
//                         </div>
//                     </div>
//                     <h1>Pedido realizado</h1>
//                     <h2 className='bold'>Folio: {orderDetails.folio}</h2>
//                 </div>
//                 <p>Puedes checar los detalles de tu pedido en “Mis Pedidos”.</p>
//                 <div className='mtb-16'>
//                     <div className='bold'>Nombre:</div>
//                     <p className='mb-16'>{user.full_name || 'No disponible'}</p>
//                     <div className='bold'>Teléfono:</div>
//                     <p className='mb-16'>{user.phone || 'No disponible'}</p>
    
//                     {/* Mostrar la fecha de entrega solo si el método no es 'national-shipping' */}
//                     {orderDetails.method !== 'national-shipping' && (
//                         <>
//                             <div className='bold'>Fecha de entrega:</div>
//                             <p className='mb-16'>{formattedDeliveryDate}</p>
//                         </>
//                     )}
    
//                     {/* Mostrar la hora si está disponible */}
//                     {orderDetails.hour && (
//                         <>
//                             <div className='bold'>Horario de entrega:</div>
//                             <p className='mb-16'>{orderDetails.hour}</p>
//                         </>
//                     )}
    
//                     {orderDetails.store && (
//                         <>
//                             <div className='bold'>Lugar de entrega:</div>
//                             <p className='mb-16'>{orderDetails.store}</p>
//                         </>
//                     )}
    
//                     {orderDetails.address && (
//                         <>
//                             <div className='bold'>Dirección de entrega:</div>
//                             <p className='mb-16'>{orderDetails.address}</p>
//                         </>
//                     )}
    
//                     {orderDetails.method === 'store-pickup' && (
//                         <p className='bold mtb-16'>
//                             Nota: Recuerda que solo tienes un día para recoger tu pedido después de realizarlo.
//                             Si quisieras expandir el plazo a 7 días como máximo puedes transferir el 50% del total de pedido.
//                         </p>
//                     )}
    
//                     <button className="btn" onClick={handleGoHome}>Volver al inicio</button>
//                 </div>
//             </div>
//         </div>
//     </section>
//     );
// };

// export default OrderPlaced;
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';

const OrderPlaced = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAuth();

    const state = location?.state || {};
    const { orderDetails, formattedDeliveryDate } = state;

    // Validación para manejar errores
    if (!orderDetails) {
        return (
            <div className="container mt-20 mb-75">
                <div className="order-placed-container card-white">
                    <h2>Algo salió mal.</h2>
                    <p>No se han recibido los detalles del pedido.</p>
                    <button className="btn" onClick={() => navigate('/')}>
                        Volver al inicio
                    </button>
                </div>
            </div>
        );
    }

    // Manejar el botón para volver al inicio
    const handleGoHome = () => {
        navigate('/');
    };

    console.log('Detalles del pedido:', orderDetails);

    return (
        <section>
            <div className="container mt-20 mb-75">
                <div className="order-placed-container card-white">
                    <div className="f-h jc-c a-c column gap-16 mb-16">
                        <div className="success-checkmark">
                            <div className="check-icon">
                                <span className="icon-line line-tip"></span>
                                <span className="icon-line line-long"></span>
                                <div className="icon-circle"></div>
                                <div className="icon-fix"></div>
                            </div>
                        </div>
                        <h1>Pedido realizado</h1>
                        <h2 className="bold">Folio: {orderDetails.folio || 'No disponible'}</h2>
                    </div>
                    <p>Puedes checar los detalles de tu pedido en “Mis Pedidos”.</p>
                    <div className="mtb-16">
                        <div className="bold">Nombre:</div>
                        <p className="mb-16">{user?.full_name || orderDetails.guest || 'No disponible'}</p>
                        <div className="bold">Teléfono:</div>
                        <p className="mb-16">{user?.phone || orderDetails.guest_phone || 'No disponible'}</p>

                        {orderDetails.method !== 'national-shipping' && (
                            <>
                                <div className="bold">Fecha de entrega:</div>
                                <p className="mb-16">{formattedDeliveryDate || 'No disponible'}</p>
                            </>
                        )}

                        {orderDetails.hour && (
                            <>
                                <div className="bold">Horario de entrega:</div>
                                <p className="mb-16">{orderDetails.hour}</p>
                            </>
                        )}

                        {orderDetails.store && (
                            <>
                                <div className="bold">Lugar de entrega:</div>
                                <p className="mb-16">{orderDetails.store}</p>
                            </>
                        )}

                        {orderDetails.address && (
                            <>
                                <div className="bold">Dirección de entrega:</div>
                                <p className="mb-16">{orderDetails.address}</p>
                            </>
                        )}

                        {orderDetails.method === 'store-pickup' && (
                            <p className="bold mtb-16">
                                Nota: Recuerda que solo tienes un día para recoger tu pedido después de realizarlo.
                                Si quisieras expandir el plazo a 7 días como máximo puedes transferir el 50% del total del pedido.
                            </p>
                        )}

                        <button className="btn" onClick={handleGoHome}>Volver al inicio</button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OrderPlaced;