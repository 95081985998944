import React, { useState, useEffect } from 'react';
import { supabase } from './supabaseClient';
import { Button, Table, Select } from 'antd';

const { Option } = Select;

const InventoryReport = () => {
  const [loading, setLoading] = useState(false);
  const [inventoryReport, setInventoryReport] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterType, setFilterType] = useState('all');

  const fetchInventoryReport = async () => {
    setLoading(true);
    try {
      const { data: products, error } = await supabase
        .from('products')
        .select('id, name, price, pieces, reorder_level, last_updated');
  
      if (error) {
        console.error('Error al obtener el reporte de inventario:', error);
        return;
      }
  
      // Filtrar productos con existencias mayores a cero
      const filteredProducts = products.filter(product => product.pieces > 0);
  
      const sortedInventoryReport = filteredProducts
        .map((product) => {
          let status = 'Stock suficiente';
          if (product.pieces <= product.reorder_level) {
            status = 'Necesita reorden';
          } else if (product.pieces <= product.reorder_level * 1.2) {
            status = 'Cerca del punto de reorden';
          }
  
          return {
            ...product,
            status,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
  
      setInventoryReport(sortedInventoryReport);
      setFilteredData(sortedInventoryReport);
    } catch (error) {
      console.error('Error al generar el reporte de inventario:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (value) => {
    setFilterType(value);

    switch (value) {
      case 'all':
        const sortedByName = [...inventoryReport].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setFilteredData(sortedByName);
        break;
      case 'reorder':
        setFilteredData(inventoryReport.filter(product => product.pieces <= product.reorder_level));
        break;
      case 'stock-sufficient':
        const sortedStockSufficient = inventoryReport
          .filter(product => product.pieces > product.reorder_level * 1.2)
          .sort((a, b) => b.pieces - a.pieces);
        setFilteredData(sortedStockSufficient);
        break;
      default:
        setFilteredData(inventoryReport);
    }
  };

  useEffect(() => {
    fetchInventoryReport();
    const intervalId = setInterval(fetchInventoryReport, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    { title: 'Nombre', dataIndex: 'name', key: 'name' },
    { title: 'P.U.', dataIndex: 'price', key: 'price' },
    { title: 'Existencias', dataIndex: 'pieces', key: 'pieces' },
  ];

  const printInventoryReport = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Reporte de Inventario</title>
          <style>
            @media print {
              body {
                font-family: Arial, sans-serif;
                font-size: 10px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-top: 20px;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            }
            body {
              font-family: Arial, sans-serif;
              margin: 50px;
            }
            h1 {
              text-align: center;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 20px;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>
          <h1>Reporte de Inventario</h1>
          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>P.U.</th>
                <th>Existencias</th>
              </tr>
            </thead>
            <tbody>
              ${filteredData.map(product => `
                <tr>
                  <td>${product.name}</td>
                  <td>${product.price}</td>
                  <td>${product.pieces}</td>
                </tr>
              `).join('')}
            </tbody>
          </table>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = function() {
      printWindow.print();
    };
  };


  return (
    <section className='mb-75'>
      <div className="container">
        <h1 className='txt-align-center'>Reporte de Inventario</h1>
        <div className='f-h wrap-noreverse center-mobile'>
          <Button type="primary" className="btn btn-inventory" onClick={fetchInventoryReport} loading={loading}>
            Actualizar Manualmente
          </Button>
          <Select defaultValue="all" onChange={handleFilterChange} >
            <Option value="all">Todos los productos (A-Z)</Option>
            <Option value="reorder">Pocas Existencias</Option>
            <Option value="stock-sufficient">Suficientes Existencias</Option>
          </Select>
          <div className="f-h">
            <Button className='btn-yellow btn-print' onClick={printInventoryReport}>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 7H7V6h10v1zm0 12H7v-6h10v6zm2-12V3H5v4H1v8.996C1 17.103 1.897 18 3.004 18H5v3h14v-3h1.996A2.004 2.004 0 0 0 23 15.996V7h-4z" fill="#ffffff"></path></g>
              </svg>
            </Button>

          </div>
        </div>
        <div id="table-container">
          <Table dataSource={filteredData} columns={columns} rowKey="id" />
        </div>
      </div>
    </section>
  );
};

export default InventoryReport;