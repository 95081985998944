// import React, { useState, useEffect } from 'react';
// import { Button, Modal, DatePicker } from 'antd'; 
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../../context/authContext';
// import DropdownForeign from './DropdownForeign';
// import DropdownDelivery from './DropDownDelivery';
// import { useLocation } from 'react-router-dom';

// import DropDownPickUp from './DropDownPickUp';
// import moment from 'moment';
// import 'moment/locale/es'; 

// const ShippingMethod = ({ onNext}) => {    
//     const location = useLocation();
//     const cartTotal = location.state?.cartTotal || 0;
//     const [selectedMethod, setSelectedMethod] = useState(null);
//     const [selectedStore, setSelectedStore] = useState(''); 
//     const [selectedAddress, setSelectedAddress] = useState(''); 
//     const [selectedHour, setSelectedHour] = useState(''); // Nuevo estado para el horario
//     const [deliveryDate, setDeliveryDate] = useState(null); // Se cambia a null inicialmente
//     const navigate = useNavigate();
//     const { user } = useAuth();
//     const [name, setName] = useState(user?.user_metadata?.full_name || '');
//     const [phone, setPhone] = useState(user?.phone || ''); // Estado para el teléfono

//     useEffect(() => {
//         console.log("Cart Total:", cartTotal);
//     }, [cartTotal]);

//     // Cálculo de fecha para "foreign-shipping"
//     useEffect(() => {
//         if (selectedMethod === 'foreign-shipping' && selectedAddress) {
//             const today = moment();
//             let deliveryDate = moment(today);

//             if (selectedAddress.includes('Actopan')) {
//                 // Calcular el próximo jueves
//                 deliveryDate.add(1, 'days'); // Iniciar un día después
//                 while (deliveryDate.day() !== 4) {
//                     deliveryDate.add(1, 'days');
//                 }
//             } else if (selectedAddress.includes('Tulancingo') || selectedAddress.includes('Cd. Sahagún')) {
//                 // Calcular el próximo viernes
//                 deliveryDate.add(1, 'days'); // Iniciar un día después
//                 while (deliveryDate.day() !== 5) {
//                     deliveryDate.add(1, 'days');
//                 }
//             }
//             setDeliveryDate(deliveryDate);
//         }
//     }, [selectedMethod, selectedAddress]);


//     // Cálculo de fecha para "pickup-point"
//     useEffect(() => {
//         if (selectedMethod === 'pickup-point') {
//             const today = moment();
//             let deliveryDay = moment(today);
//             // Calcular el próximo martes
//             deliveryDay.add(1, 'days'); // Iniciar un día después
//             while (deliveryDay.day() !== 2) {
//                 deliveryDay.add(1, 'days');
//             }
//             setDeliveryDate(deliveryDay);
//         }
//     }, [selectedMethod]);

//     // Cálculo de fecha para "store-pickup"
//     const getNextBusinessDay = () => {
//         const today = moment();
//         let nextBusinessDay = moment(today).add(1, 'days');
//         while (nextBusinessDay.day() === 0) { // Evitar domingos
//             nextBusinessDay.add(1, 'days');
//         }
//         return nextBusinessDay;
//     };

//     const calculatePickupPointDate = () => {
//         const now = moment();
  
//         // Lunes 17:30 (5:30 p.m.)
//         const mondayCutoff = moment().day(1).hour(17).minute(30).second(0).millisecond(0);
//         const isMondayBeforeCutoff =
//           now.day() === 1 && now.isBefore(mondayCutoff);
  
//         if (isMondayBeforeCutoff) {
//           // Se entrega "mañana" (martes de la misma semana) a las 12:00
//           return now.clone()
//             .day(2)   // martes = day(2)
//             .hour(12)
//             .minute(0)
//             .second(0)
//             .millisecond(0);
//         } else {
//           // Se entrega el martes de la siguiente semana a las 12:00
//           // martes actual + 7 días => day(9)
//           return now.clone()
//             .day(9)   // martes + 7
//             .hour(12)
//             .minute(0)
//             .second(0)
//             .millisecond(0);
//         }
//       };

//     const handleSelectMethod = (method) => {
//         if (method === 'package-shipping' && Number(cartTotal) < 200) {
//             Modal.warning({
//                 title: 'Monto mínimo requerido',
//                 content: 'El monto mínimo para el envío a domicilio es de $200. Agrega más productos al carrito para habilitar esta opción.',
//             });
//             return;
//         }
    
//         setSelectedMethod(method);
    
//         if (method === 'store-pickup') {
//             const nextBusinessDay = getNextBusinessDay();
//             setDeliveryDate(nextBusinessDay);
//             setSelectedAddress("Siembra 193, Pachuca, México");
//         } else if (method === 'foreign-shipping' || method === 'pickup-point') {
//             setDeliveryDate(null);
//         }
//     };

//     // const disabledDateForPackageShipping = (current) => {
//     //     const today = moment().startOf('day');
//     //     const isBeforeToday = current && current < today;
        
//     //     // Solo permitir los próximos 3 días hábiles (sin contar sábado y domingo)
//     //     const nextThreeBusinessDays = [];
//     //     let day = today.clone().add(1, 'day');
//     //     while (nextThreeBusinessDays.length < 3) {
//     //         if (day.day() !== 0 && day.day() !== 6) {
//     //             nextThreeBusinessDays.push(day.clone());
//     //         }
//     //         day.add(1, 'day');
//     //     }
    
//     //     const isValidDay = nextThreeBusinessDays.some(validDay => current.isSame(validDay, 'day'));
    
//     //     return isBeforeToday || !isValidDay;
//     // };

//     const disabledDateForPackageShipping = (current) => {
//         // No permitir fechas pasadas
//         if (!current || current < moment().startOf('day')) {
//           return true;
//         }
    
//         // Definir la hora límite (17:30 = 5:30 pm) del día de hoy
//         const now = moment();
//         const deadlineToday = moment().set({
//           hour: 17,
//           minute: 30,
//           second: 0,
//           millisecond: 0,
//         });
//         const isAfterDeadline = now.isAfter(deadlineToday);
    
//         // Determinar si empezamos a contar "a partir de mañana" (offset=1)
//         // o "pasado mañana" (offset=2) si ya pasó la hora límite.
//         const offset = isAfterDeadline ? 2 : 1;
    
//         // Construimos la lista de los PRÓXIMOS 3 días hábiles
//         // (excluyendo viernes: 5, sábado: 6 y domingo: 0).
//         const validDates = [];
//         let checkDay = now.clone().add(offset, 'day').startOf('day');
    
//         while (validDates.length < 3) {
//           // Saltar viernes (5), sábado (6) y domingo (0)
//           if (![0, 5, 6].includes(checkDay.day())) {
//             validDates.push(checkDay.clone());
//           }
//           checkDay.add(1, 'day');
//         }
    
//         // Solo habilitar las fechas que están dentro de 'validDates'
//         return !validDates.some((validDay) => current.isSame(validDay, 'day'));
//       };

//     const disabledDate = (current) => {
//         const today = moment().startOf('day');
//         const isTooEarly = current && current < today;
//         const isTooLate = current && current > moment(today).add(3, 'days').endOf('day'); // Permitir solo 3 días después de hoy
//         const isNotAllowedDay = current && (current.day() === 0 || current.day() === 5 || current.day() === 6); // Excluir viernes, sábado y domingo
        
//         return isTooEarly || isTooLate || isNotAllowedDay;
//     };
//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (!selectedMethod) {
//             Modal.error({
//                 title: 'Error',
//                 content: 'Debes seleccionar un método de envío.',
//             });
//             return;
//         } else {
//             // Validación para el método de envío a domicilio con monto mínimo
//             if (selectedMethod === 'package-shipping' && cartTotal < 200) {
//                 Modal.warning({
//                     title: 'Monto mínimo requerido',
//                     content: 'El monto mínimo para el envío a domicilio es de $200. Agrega más productos al carrito para poder continuar.',
//                 });
//                 return;
//             }
                
//                 let deliveryDateToSave = null;
                
//             // Si la entrega requiere fecha y está seleccionada, se guarda
//             if ((selectedMethod !== 'national-shipping' && deliveryDate)) {
//                 deliveryDateToSave = deliveryDate.format('YYYY-MM-DD');
//             }    
//             onNext({
//                 method: selectedMethod,
//                 name,
//                 store: selectedStore,
//                 address: selectedAddress,
//                 deliveryDate: deliveryDateToSave,
//                 hour: selectedHour,
//             });
    
//             navigate('/order-summary');
//         }

//         let deliveryDateToSave = null;
//         if (selectedMethod !== 'national-shipping' && deliveryDate) {
//             deliveryDateToSave = deliveryDate.format('YYYY-MM-DD');
//         }

//         const details = {
//             method: selectedMethod,
//             name,
//             phone,
//             store: selectedStore,
//             address: selectedAddress,
//             deliveryDate: deliveryDateToSave,
//             hour: selectedHour,
//         };
//         console.log('Detalles enviados a OrderSummary:', details);

//         navigate('/order-summary', { state: { details } });
//     };

//     const handleStoreSelect = (store) => setSelectedStore(store);
//     const handleAddressSelect = (address) => setSelectedAddress(address);

//     return (
//         <section>
//             <div className="container mb-75">
//                 <div className='shipping-method mtb-20'>
//                     <button className='btn btn-yellow mb-16' onClick={() => navigate(-1)}>
//                         Regresar
//                     </button>
//                     <h2 className='mb-16'>Elige tu forma de entrega</h2>

//                     {/* Store Pickup */}
//                     <div
//                         className={`shipping-option ${selectedMethod === 'store-pickup' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('store-pickup')}
//                     >
//                         <div className='first-wrapper'>
//                             <span>Recoger en tienda</span>
//                             <div className='method_price'>
//                                 Gratis
//                             </div>
//                         </div>
//                         {selectedMethod === 'store-pickup' && (
//                             <form className='store-pickup'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                 {!user && (
//                                     <>
//                                         <label>Teléfono</label>
//                                         <input
//                                             type="text"
//                                             value={phone}
//                                             onChange={(e) => setPhone(e.target.value)}
//                                             required
//                                         />
//                                     </>
//                                 )}
//                                 </div>
//                             </form>
//                         )}
//                     </div>

//                     {/* Foreign Shipping */}
//                     <div
//                         className={`shipping-option ${selectedMethod === 'foreign-shipping' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('foreign-shipping')}
//                     >
//                         <div className='first-wrapper'>
//                             <span>Envío foráneo</span>
//                             <div className='method_price'>
//                                 Gratis
//                             </div>
//                         </div>
//                         {selectedMethod === 'foreign-shipping' && (
//                             <form className='foreign-shipping'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                     {!user && (
//                                         <>
//                                             <label>Teléfono</label>
//                                             <input
//                                                 type="text"
//                                                 value={phone}
//                                                 onChange={(e) => setPhone(e.target.value)}
//                                                 required
//                                             />
//                                         </>
//                                     )}
//                                     <p className='mb-16'>Entregar en:</p>
//                                     <DropdownForeign onSelect={handleAddressSelect} />
//                                 </div>
//                             </form>
//                         )}
//                     </div>

//                     {/* Pickup Point */}
//                     <div
//                         className={`shipping-option ${selectedMethod === 'pickup-point' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('pickup-point')}
//                     >
//                         <div className='first-wrapper'>
//                             <span>Punto de entrega</span>
//                             <div className='method_price'>
//                                 Gratis
//                             </div>
//                         </div>
//                         {selectedMethod === 'pickup-point' && (
//                             <form className='pickup-point'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                     {!user && (
//                                         <>
//                                             <label>Teléfono</label>
//                                             <input
//                                                 type="text"
//                                                 value={phone}
//                                                 onChange={(e) => setPhone(e.target.value)}
//                                                 required
//                                             />
//                                         </>
//                                     )}
//                                     <p className='mb-16'>Selecciona un punto de entrega:</p>
//                                     <DropDownPickUp onSelect={handleAddressSelect} />
//                                 </div>
//                             </form>
//                         )}
//                     </div>

//                     {/* Package Shipping */}
//                     <div
//                         className={`shipping-option ${selectedMethod === 'package-shipping' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('package-shipping')}
//                     >
//                         <div className='first-wrapper mb-10'>
//                             <span>Envío a domicilio</span>
//                             <div className='method_price'>
//                                 $50.00
//                             </div>
//                         </div>
//                         <p className='mb-5'>Disponible para Pachuca y Mineral de la Reforma. Monto mínimo: $200.</p>

//                         {selectedMethod === 'package-shipping' && (
//                             <form className='package-shipping'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                     {!user && (
//                                         <>
//                                             <label>Teléfono</label>
//                                             <input
//                                                 type="text"
//                                                 value={phone}
//                                                 onChange={(e) => setPhone(e.target.value)}
//                                                 required
//                                             />
//                                         </>
//                                     )}
//                                     <label className='mtb-16'>Dirección de envío:</label>
//                                     <input
//                                         type="text"
//                                         value={selectedAddress} 
//                                         onChange={(e) => setSelectedAddress(e.target.value)} 
//                                         placeholder="Ingresa la dirección de envío"
//                                         required
//                                     />
//                                     {/* <DatePicker
//                                         format="YYYY-MM-DD"
//                                         onChange={(date) => {
//                                             setDeliveryDate(date);
//                                         }}
//                                     /> */}
//                                     {/* <DatePicker
//                                         format="YYYY-MM-DD"
//                                         disabledDate={disabledDateForPackageShipping}
//                                         onChange={(date) => {
//                                             setDeliveryDate(date);
//                                         }}
//                                     /> */}
//                                     <DatePicker
//                                         format="YYYY-MM-DD"
//                                         disabledDate={disabledDateForPackageShipping}
//                                         onChange={(date) => setDeliveryDate(date)}
//                                     />
//                                     <p>¡Importante! Nuestro horario de reparto es de 10:00 a.m. a 03:00 p.m.</p>
//                                     <label className='mtb-16'>Agenda tu entrega</label>
//                                     <input
//                                         type="text"
//                                         value={selectedHour}
//                                         onChange={(e) => setSelectedHour(e.target.value)}
//                                         placeholder="Ejemplo: 11:00 a 01:00"
//                                         required
//                                     />
//                                 </div>
//                             </form>
//                         )}
//                     </div>


//                     {/* National Shipping */}
//                     <div
//                         className={`shipping-option ${selectedMethod === 'national-shipping' ? 'selected' : ''}`}
//                         onClick={() => handleSelectMethod('national-shipping')}
//                     >
//                         <div className='first-wrapper mb-10'>
//                             <span>Paquetería</span>
//                             <div className='method_price'>
//                                 $350.00
//                             </div>
//                         </div>
//                         <p className='mb-5'>Envío a toda la república</p>

//                         {selectedMethod === 'national-shipping' && (
//                             <form className='national-shipping'>
//                                 <div>
//                                     <label className='mtb-16'>Nombre y apellido</label>
//                                     <input
//                                         type="text"
//                                         value={name}
//                                         onChange={(e) => setName(e.target.value)}
//                                         placeholder="Nombre y apellido"
//                                         required
//                                     />
//                                     {!user && (
//                                         <>
//                                             <label>Teléfono</label>
//                                             <input
//                                                 type="text"
//                                                 value={phone}
//                                                 onChange={(e) => setPhone(e.target.value)}
//                                                 required
//                                             />
//                                         </>
//                                     )}
//                                     <label className='mtb-16'>Dirección de envío:</label>
//                                     <input
//                                         type="text"
//                                         value={selectedAddress}
//                                         onChange={(e) => setSelectedAddress(e.target.value)}
//                                         placeholder="Ingresa la dirección de envío"
//                                         required
//                                     />
//                                     <p className='font-size-xs'>
//                                         Las medidas del paquete deben ser 50x50x50 con un peso máximo de 25kg. Si las dimensiones son mayores, cotizamos su envío vía WhatsApp.
//                                     </p>
//                                 </div>
//                             </form>
//                         )}

//                 </div>
//                 </div>
//                     <button
//                         className='confirm-button'
//                         onClick={handleSubmit}
//                         // disabled={
//                         //     (!selectedMethod || !name) ||
//                         //     (selectedMethod === 'package-shipping' && (!deliveryDate || !selectedAddress || cartTotal < 200)) ||
//                         //     (selectedMethod === 'national-shipping' && (!name || !selectedAddress)) || 
//                         //     (selectedMethod === 'foreign-shipping' && !selectedAddress) ||
//                         //     (selectedMethod === 'pickup-point' && !deliveryDate)
//                         // }
//                     >
//                         Confirmar método de envío
//                     </button>
//             </div>
//         </section>
//     );
// };

// export default ShippingMethod;

import React, { useState, useEffect } from 'react';
import { Button, Modal, DatePicker } from 'antd'; 
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import DropdownForeign from './DropdownForeign';
import DropDownPickUp from './DropDownPickUp';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es'; 

const ShippingMethod = ({ onNext }) => {
  const location = useLocation();
  const cartTotal = location.state?.cartTotal || 0;

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedHour, setSelectedHour] = useState('');
  const [deliveryDate, setDeliveryDate] = useState(null);

  const navigate = useNavigate();
  const { user } = useAuth();
  const [name, setName] = useState(user?.user_metadata?.full_name || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [isFormValid, setIsFormValid] = useState(false);

  // const validateForm = () => {
  //   if (!selectedMethod) return false;
  
  //   switch (selectedMethod) {
  //     case 'store-pickup':
  //       return name.trim() !== '';
  
  //     case 'foreign-shipping':
  //       return name.trim() !== '' && phone.trim() !== '' && selectedAddress.trim() !== '';
  
  //     case 'pickup-point':
  //       return name.trim() !== '' && phone.trim() !== '' && selectedAddress.trim() !== '';
  
  //     case 'package-shipping':
  //       return (
  //         name.trim() !== '' &&
  //         phone.trim() !== '' &&
  //         selectedAddress.trim() !== '' &&
  //         deliveryDate &&
  //         selectedHour.trim() !== ''
  //       );
  
  //     case 'national-shipping':
  //       return name.trim() !== '' && phone.trim() !== '' && selectedAddress.trim() !== '';
  
  //     default:
  //       return false;
  //   }
  // };
  const validateForm = () => {
    if (!selectedMethod) return false;
  
    const safeName = name?.toString().trim() || '';
    const safePhone = phone?.toString().trim() || '';
    const safeAddress = selectedAddress?.toString().trim() || '';
    const safeHour = selectedHour?.toString().trim() || '';
  
    switch (selectedMethod) {
      case 'store-pickup':
        return safeName !== '';
  
      case 'foreign-shipping':
        return safeName !== '' && safePhone !== '' && safeAddress !== '';
  
      case 'pickup-point':
        return safeName !== '' && safePhone !== '' && safeAddress !== '';
  
      case 'package-shipping':
        return safeName !== '' && safePhone !== '' && safeAddress !== '' && deliveryDate && safeHour !== '';
  
      case 'national-shipping':
        return safeName !== '' && safePhone !== '' && safeAddress !== '';
  
      default:
        return false;
    }
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [selectedMethod, name, phone, selectedAddress, deliveryDate, selectedHour]);
  

  // =========================================================
  //                   FUNCIONES AUXILIARES
  // =========================================================

  /**
   * 1) Envío Foráneo
   *    - Actopan => se entrega el próximo jueves.
   *    - Tulancingo o Cd. Sahagún => se entrega el próximo viernes.
   *    - Si estamos después de las 5:30 p.m. del día anterior (miércoles para jueves, jueves para viernes),
   *      se recorre a la siguiente semana.
   */
  const calculateForeignShippingDate = (address) => {
    if (!address) return null;

    const now = moment();
    let shippingDay; // 4 = jueves, 5 = viernes

    if (address.includes('Actopan')) {
      shippingDay = 4; // Jueves
    } else if (address.includes('Tulancingo') || address.includes('Cd. Sahagún')) {
      shippingDay = 5; // Viernes
    } else {
      // Si no coincide con esas zonas, puedes retornar null o manejar otra lógica
      return null;
    }

    // Buscamos el siguiente "shippingDay" a partir de "mañana"
    let date = now.clone().add(1, 'day');
    while (date.day() !== shippingDay) {
      date.add(1, 'day');
    }

    // Ahora, definimos el "día anterior a la entrega" a las 17:30
    // Ej: si la entrega es jueves (4), el día anterior es miércoles (3) a las 17:30
    //     si la entrega es viernes (5), el día anterior es jueves (4) a las 17:30
    const dayBefore = date.clone()
      .subtract(1, 'day')
      .set({ hour: 17, minute: 30, second: 0, millisecond: 0 });

    // Si ya estamos después de ese "dayBefore", saltamos a la siguiente semana
    if (now.isAfter(dayBefore)) {
      date.add(7, 'days'); // siguiente jueves o viernes
    }

    return date;
  };

  /**
   * 2) Punto Fijo (pickup-point):
   *    - Siempre martes 12pm.
   *    - Si hoy es lunes antes de 5:30 pm, se entrega mañana (martes de esta semana).
   *    - Caso contrario, se recorre al martes de la próxima semana.
   */
  const calculatePickupPointDate = () => {
    const now = moment();
    const mondayCutoff = moment()
      .day(1)
      .hour(17)
      .minute(30)
      .second(0)
      .millisecond(0);

    const isMondayBeforeCutoff = now.day() === 1 && now.isBefore(mondayCutoff);

    if (isMondayBeforeCutoff) {
      // Martes de esta misma semana, 12:00
      return now.clone().day(2).hour(12).minute(0).second(0).millisecond(0);
    } else {
      // Martes de la próxima semana
      return now.clone().day(9).hour(12).minute(0).second(0).millisecond(0);
    }
  };

  /**
   * 3) Store Pickup: Próximo día hábil (evitando domingo).
   *    - Si quieres evitar sábado también, ajusta la condición.
   */
  const getNextBusinessDay = () => {
    const today = moment();
    let nextBusinessDay = today.clone().add(1, 'days');
    while (nextBusinessDay.day() === 0) {
      // Si deseas saltar sábado: while (nextBusinessDay.day() === 0 || nextBusinessDay.day() === 6)
      nextBusinessDay.add(1, 'days');
    }
    return nextBusinessDay;
  };

  /**
   * 4) Package-Shipping:
   *    - Solo 3 días hábiles disponibles.
   *    - Excluye viernes(5), sábado(6) y domingo(0).
   *    - Si ya pasaron las 5:30 pm de hoy, omitimos el "día siguiente" inmediato.
   */
  const disabledDateForPackageShipping = (current) => {
    // a) No permitir fechas pasadas
    if (!current || current < moment().startOf('day')) {
      return true;
    }

    // b) Excluir viernes (5), sábado (6) y domingo (0)
    if ([0, 5, 6].includes(current.day())) {
      return true;
    }

    // c) Revisar si ya pasamos las 5:30 pm de HOY
    const now = moment();
    const deadlineToday = moment().set({ hour: 17, minute: 30, second: 0, millisecond: 0 });
    const isAfterDeadline = now.isAfter(deadlineToday);

    // d) Construir una lista con los próximos 3 días hábiles
    const validDates = [];
    const offset = isAfterDeadline ? 2 : 1; // si es afterDeadline, empezamos desde "pasado mañana"
    let checkDay = now.clone().add(offset, 'day').startOf('day');

    while (validDates.length < 3) {
      if (![0, 5, 6].includes(checkDay.day())) {
        validDates.push(checkDay.clone());
      }
      checkDay.add(1, 'day');
    }

    // e) Deshabilitar si no está en la lista de válidos
    return !validDates.some((validDay) => current.isSame(validDay, 'day'));
  };

  // =========================================================
  //                    EFECTOS PRINCIPALES
  // =========================================================

  // Cuando cambia la dirección en foreign-shipping, recalculamos
  useEffect(() => {
    if (selectedMethod === 'foreign-shipping' && selectedAddress) {
      const date = calculateForeignShippingDate(selectedAddress);
      setDeliveryDate(date);
    }
  }, [selectedMethod, selectedAddress]);

  // Cuando se selecciona pickup-point, se calcula la fecha automáticamente
  useEffect(() => {
    if (selectedMethod === 'pickup-point') {
      const pickupDate = calculatePickupPointDate();
      setDeliveryDate(pickupDate);
    }
  }, [selectedMethod]);

  // Cuando se selecciona store-pickup, buscamos siguiente día hábil
  useEffect(() => {
    if (selectedMethod === 'store-pickup') {
      const nextDay = getNextBusinessDay();
      setDeliveryDate(nextDay);
    }
  }, [selectedMethod]);

  // =========================================================
  //                      HANDLERS
  // =========================================================

  const handleSelectMethod = (method) => {
    // Validación de monto mínimo para envío a domicilio
    if (method === 'package-shipping' && Number(cartTotal) < 200) {
      Modal.warning({
        title: 'Monto mínimo requerido',
        content:
          'El monto mínimo para el envío a domicilio es de $200. Agrega más productos para habilitar esta opción.',
      });
      return;
    }

    setSelectedMethod(method);

    // Si se cambia a "pickup-point" o "store-pickup", la fecha se setea en los useEffect.
    // Para limpiar la fecha si cambiamos de método:
    if (method !== 'pickup-point' && method !== 'store-pickup' && method !== 'foreign-shipping') {
      setDeliveryDate(null);
    }
  };

  // 
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedMethod) {
      Modal.error({
        title: 'Error',
        content: 'Debes seleccionar un método de envío.',
      });
      return;
    }
  
    let deliveryDateToSave = null;
    if (deliveryDate) {
      deliveryDateToSave = deliveryDate.format('YYYY-MM-DD HH:mm');
    }
  
    const details = {
      method: selectedMethod || '',
      name: name || '',
      phone: phone || '',
      store: selectedStore || '',
      address: selectedAddress || '',
      deliveryDate: deliveryDateToSave || '',
      hour: selectedHour || '',
    };
  
    console.log('Detalles enviados a OrderSummary:', details);
  
    // Asegurar que no haya valores null o undefined en el state para evitar errores
    navigate('/order-summary', { state: { details } });
  };
  
  // =========================================================
  //                     RENDER DEL COMPONENTE
  // =========================================================

  return (
    <section>
      <div className="container mb-75">
        <div className="shipping-method mtb-20">
          <button className="btn btn-yellow mb-16" onClick={() => navigate(-1)}>
            Regresar
          </button>
          <h2 className="mb-16">Elige tu forma de entrega</h2>

          {/* ================== STORE PICKUP ================== */}
          <div
            className={`shipping-option ${selectedMethod === 'store-pickup' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('store-pickup')}
          >
            <div className="first-wrapper">
              <span>Recoger en tienda</span>
              <div className="method_price">Gratis</div>
            </div>
            {selectedMethod === 'store-pickup' && (
              <form className="store-pickup">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  {deliveryDate && (
                    <p className="mtb-16">
                      Fecha de recogida en tienda:
                      <br />
                      <strong>{deliveryDate.format('dddd DD [de] MMMM')}</strong>
                    </p>
                  )}
                </div>
              </form>
            )}
          </div>

          {/* ================= FOREIGN SHIPPING ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'foreign-shipping' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('foreign-shipping')}
          >
            <div className="first-wrapper">
              <span>Envío foráneo</span>
              <div className="method_price">Gratis</div>
            </div>
            {selectedMethod === 'foreign-shipping' && (
              <form className="foreign-shipping">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <p className="mb-16">Zona de entrega:</p>
                  <DropdownForeign onSelect={(addr) => setSelectedAddress(addr)} />

                  {deliveryDate && (
                    <div className="mtb-16">
                      <p>
                        <strong>Fecha de entrega estimada:</strong>
                      </p>
                      <p>
                        {deliveryDate.format('dddd DD [de] MMMM')}
                        <br />
                        (Recuerda que si haces el pedido después de las 5:30 pm
                        del día anterior de reparto, se recorre a la siguiente semana.)
                      </p>
                    </div>
                  )}
                </div>
              </form>
            )}
          </div>

          {/* ================= PICKUP POINT ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'pickup-point' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('pickup-point')}
          >
            <div className="first-wrapper">
              <span>Punto de entrega (solo martes a las 12pm)</span>
              <div className="method_price">Gratis</div>
            </div>
            {selectedMethod === 'pickup-point' && (
              <form className="pickup-point">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <p className="mtb-16">Selecciona un punto de entrega:</p>
                  <DropDownPickUp onSelect={(addr) => setSelectedAddress(addr)} />

                  {deliveryDate && (
                    <p className="mtb-16">
                      Entrega programada:
                      <br />
                      <strong>
                        {deliveryDate.format('dddd DD [de] MMMM [a las] HH:mm [hrs]')}
                      </strong>
                    </p>
                  )}
                  <small className="font-size-xs">
                    Si tu pedido se realiza el lunes antes de las 5:30 pm, se entrega
                    el martes de esta semana. De lo contrario, el martes de la siguiente.
                  </small>
                </div>
              </form>
            )}
          </div>

          {/* ================= PACKAGE SHIPPING ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'package-shipping' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('package-shipping')}
          >
            <div className="first-wrapper mb-10">
              <span>Envío a domicilio</span>
              <div className="method_price">$50.00</div>
            </div>
            <p className="mb-5">
              Disponible para Pachuca y Mineral de la Reforma. 
              Monto mínimo: $200.
            </p>
            {selectedMethod === 'package-shipping' && (
              <form className="package-shipping">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <label className="mtb-16">Dirección de envío:</label>
                  <input
                    type="text"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    placeholder="Ingresa la dirección de envío"
                    required
                  />

                  <p>Selecciona tu fecha de entrega (solo 3 días hábiles próximos):</p>
                  <DatePicker
                    format="YYYY-MM-DD"
                    disabledDate={disabledDateForPackageShipping}
                    onChange={(date) => setDeliveryDate(date)}
                  />

                  <p className="mtb-8">
                    Horario de reparto: 10:00 a.m. a 03:00 p.m.
                    <br />
                    *Si haces pedido después de las 5:30 pm de hoy, 
                    mañana no estará disponible (salta al siguiente día hábil).
                  </p>

                  <label className="mtb-16">Agenda tu entrega (franja horaria):</label>
                  <input
                    type="text"
                    value={selectedHour}
                    onChange={(e) => setSelectedHour(e.target.value)}
                    placeholder="Ejemplo: 11:00 a 01:00"
                    required
                  />
                </div>
              </form>
            )}
          </div>

          {/* ================= NATIONAL SHIPPING ================= */}
          <div
            className={`shipping-option ${selectedMethod === 'national-shipping' ? 'selected' : ''}`}
            onClick={() => handleSelectMethod('national-shipping')}
          >
            <div className="first-wrapper mb-10">
              <span>Paquetería</span>
              <div className="method_price">$350.00</div>
            </div>
            <p className="mb-5">Envío a toda la república</p>
            {selectedMethod === 'national-shipping' && (
              <form className="national-shipping">
                <div>
                  <label className="mtb-16">Nombre y apellido</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Nombre y apellido"
                    required
                  />
                  {!user && (
                    <>
                      <label>Teléfono</label>
                      <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </>
                  )}
                  <label className="mtb-16">Dirección de envío:</label>
                  <input
                    type="text"
                    value={selectedAddress}
                    onChange={(e) => setSelectedAddress(e.target.value)}
                    placeholder="Ingresa la dirección de envío"
                    required
                  />
                  <p className="font-size-xs">
                    Medidas máximo 50x50x50 (25kg). Si excede, cotizamos envío vía WhatsApp.
                  </p>
                </div>
              </form>
            )}
          </div>

          <button className="confirm-button" onClick={handleSubmit} disabled={!isFormValid}>
            Confirmar método de envío
          </button>
        </div>
      </div>
    </section>
  );
};

export default ShippingMethod;