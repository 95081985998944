// // import React, { useState } from 'react';
// // import { useCart } from '../../context/cartContext';
// // import { useAuth } from '../../context/authContext';
// // import { supabase } from '../supabaseClient';
// // import { useNavigate } from 'react-router-dom';
// // import moment from 'moment';
// // import {  Modal } from 'antd'; 
// // import 'moment/locale/es'; // Importa el locale en español

// // const OrderSummary = ({ details }) => {
// //     const { cart, clearCart } = useCart();
// //     const navigate = useNavigate();
// //     const { user } = useAuth();
// //     const [loadingOrder, setLoadingOrder] = useState(false)

// //     const methodTranslation = {
// //         'store-pickup': 'Recoger en tienda',
// //         'foreign-shipping': 'Envío foráneo',
// //         'package-shipping': 'Envío a domicilio',
// //         'pickup-point': 'Entrega en punto fijo',
// //         'national-shipping': 'Paquetería'
// //     };

// //     const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
// //     const shippingCost = details.method === 'store-pickup' 
// //     ? 0 
// //     : details.method === 'foreign-shipping' 
// //     ? 0 
// //     : details.method === 'package-shipping' 
// //     ? 50 
// //     : details.method === 'national-shipping' 
// //     ? 350 
// //     : 0;    const total = subtotal + shippingCost;

// // const formattedDeliveryDate = details.deliveryDate
// //     ? moment(details.deliveryDate, 'YYYY-MM-DD').isValid()
// //         ? moment(details.deliveryDate, 'YYYY-MM-DD').format(
// //               details.method === 'pickup-point'
// //                   ? 'dddd, D [de] MMMM [de] YYYY [a las 12:00pm]'
// //                   : 'dddd, D [de] MMMM [de] YYYY'
// //           )
// //         : 'Fecha inválida'
// //     : null;

// // const handleGoBack = () => {
// //     navigate('/cart');
// // };



// //     const saveOrderToDatabase = async () => {
// //         const checkForDuplicateOrder = async () => {
// //             const { data, error } = await supabase
// //                 .from('orders')
// //                 .select('*')
// //                 .eq('user_id', details.userId || null)
// //                 .eq('method', details.method)
// //                 .gte('created_at', moment().subtract(10, 'seconds').toISOString());
        
// //             if (error) throw error;
// //             if (data.length > 0) {
// //                 throw new Error('Ya has realizado un pedido similar recientemente.');
// //             }
// //         };
    
// //         try {
// //             // Verificar que el carrito tenga productos
// //             if (!cart || cart.length === 0) {
// //                 throw new Error('El carrito está vacío. No se puede crear un pedido.');
// //             }
    
// //             // Verificar que el total sea mayor a 0
// //             if (total <= 0) {
// //                 throw new Error('El total del pedido es inválido.');
// //             }
    
// //             // Verificar si se ha seleccionado un método de entrega válido
// //             if (!details.method) {
// //                 throw new Error('No se ha seleccionado un método de entrega.');
// //             }
    
// //             // Validar el stock de cada producto antes de continuar
// //             for (let item of cart) {
// //                 try {
// //                     await updateProductStock(item.id, item.quantity);
// //                 } catch (stockError) {
// //                     // Manejo específico del error de stock insuficiente
// //                     Modal.error({
// //                         title: 'Error de stock insuficiente',
// //                         content: `No hay suficiente stock para el producto ID: ${item.id}.`,
// //                         onOk: () => navigate(-1), // Regresar al paso anterior
// //                     });
// //                     return; // Terminar ejecución si hay un error en el stock
// //                 }
// //             }
    
// //             const items = cart.map(item => ({
// //                 id: item.id,
// //                 name: item.name,
// //                 quantity: item.quantity,
// //                 price: item.price,
// //                 notes: item.notes || ''
// //             }));
    
// //             const deliveryDateForDB = moment(details.deliveryDate).format('YYYY-MM-DD');
    
// //             // Consulta para verificar si ya existe un pedido duplicado reciente
// //             const { data: existingOrders, error: checkError } = await supabase
// //                 .from('orders')
// //                 .select('*')
// //                 .eq('user_id', user.id)
// //                 .eq('method', details.method)
// //                 .eq('total', total)
// //                 .gte('created_at', moment().subtract(10, 'seconds').toISOString()) // Verifica si ya existe un pedido en los últimos 10 segundos
// //                 .limit(1);  // Limitamos la consulta a un solo pedido
    
// //             if (checkError) throw checkError;
    
// //             // Si ya existe un pedido similar, no procedemos con la inserción
// //             if (existingOrders.length > 0) {
// //                 throw new Error('Ya has creado un pedido similar recientemente.');
// //             }
    
// //             // Generar el folio consecutivo
// //             const orderFolio = await generateFolio(); 
    
// //             // Si no existe un pedido similar, procedemos a guardar el nuevo pedido
// //             const { data: orderData, error: orderError } = await supabase
// //                 .from('orders')
// //                 .insert([{
// //                     user_id: user.id,
// //                     delivery_date: deliveryDateForDB,
// //                     method: details.method,
// //                     store: details.store,
// //                     address: details.address,
// //                     items: items,
// //                     total: total,
// //                     subtotal: subtotal,
// //                     shipping_cost: shippingCost,
// //                     hour: details.hour,
// //                     folio: orderFolio,
// //                     print_status: false // Asegurar que al crear el pedido, no está impreso
// //                 }]);
    
// //             if (orderError) throw orderError;
    
// //             clearCart();
// //             navigate('/order-placed', {
// //                 state: {
// //                     orderDetails: {
// //                         folio: orderFolio,
// //                         store: details.store,
// //                         address: details.address,
// //                         method: details.method
// //                     },
// //                     formattedDeliveryDate,
// //                 },
// //             });
    
// //         } catch (error) {
// //             console.error('Error al procesar el pedido:', error.message);
// //             Modal.error({
// //                 title: 'Error al procesar el pedido',
// //                 content: `${error.message}`,
// //                 onOk: () => navigate(-1), // Regresar al paso anterior
// //             });
// //         } finally {
// //             setLoadingOrder(false); // Deshabilita el loader cuando el pedido se haya procesado
// //         }
// //     };
    

// //     const generateFolio = async () => {
// //         try {
// //             // Bloquear la transacción mientras se consulta el último folio
// //             const { data: lastOrder, error: lastOrderError } = await supabase
// //                 .from('orders')
// //                 .select('folio')
// //                 .order('created_at', { ascending: false }) // Ordenar por fecha de creación
// //                 .limit(1);
    
// //             if (lastOrderError) {
// //                 console.error('Error al obtener el último pedido:', lastOrderError);
// //                 throw lastOrderError;
// //             }
    
// //             let newFolioNumber;
// //             if (lastOrder && lastOrder.length > 0) {
// //                 let lastFolio = lastOrder[0].folio;
                
// //                 console.log('Último folio encontrado:', lastFolio); // Log para verificar el último folio
    
// //                 // Eliminar cualquier prefijo no numérico del folio (por ejemplo, letras)
// //                 lastFolio = lastFolio.replace(/\D/g, ''); // Remover todas las letras
                
// //                 // Verificar si el último folio es un número válido después de eliminar el prefijo
// //                 const lastFolioNumber = parseInt(lastFolio, 10);
    
// //                 if (isNaN(lastFolioNumber)) {
// //                     console.warn('El último folio no es un número válido, iniciando desde 1.');
// //                     newFolioNumber = 1;
// //                 } else {
// //                     newFolioNumber = lastFolioNumber + 1; // Incrementar el último número del folio
// //                 }
// //             } else {
// //                 console.warn('No se encontraron pedidos previos, iniciando desde 1.');
// //                 newFolioNumber = 1; // Si no hay pedidos previos, empezar desde 1
// //             }
    
// //             // Retornar el nuevo folio como un número de 7 dígitos
// //             const formattedFolio = String(newFolioNumber).padStart(7, '0'); // Ahora 7 dígitos
// //             console.log('Nuevo folio generado:', formattedFolio);
    
// //             return formattedFolio;
    
// //         } catch (error) {
// //             console.error('Error generando el folio:', error);
// //             throw error;
// //         }
// //     };

// //     const updateProductStock = async (productId, quantitySold) => {
// //         console.log(`Actualizando stock para el producto ID: ${productId} con cantidad vendida: ${quantitySold}`);
    
// //         const { data: product, error: productError } = await supabase
// //             .from('products')
// //             .select('pieces')
// //             .eq('id', productId)
// //             .single();
    
// //         if (productError) {
// //             console.error('Error al obtener el stock:', productError);
// //             throw new Error('Error al consultar el stock del producto.');
// //         }
    
// //         console.log('Producto obtenido:', product);
    
// //         const currentStock = Number(product.pieces);
// //         const soldQuantity = Number(quantitySold);
// //         const newStock = currentStock - soldQuantity;
    
// //         console.log(`Nuevo stock calculado: ${newStock}`);
    
// //         if (newStock < 0) {
// //             throw new Error(`No hay suficiente stock para el producto ID: ${productId}.`);
// //         }
    
// //         const { data: updateData, error: updateError } = await supabase
// //             .from('products')
// //             .update({ pieces: newStock })
// //             .eq('id', productId);
    
// //         if (updateError) {
// //             console.error('Error al actualizar el stock:', updateError);
// //             throw new Error('Error al actualizar el stock del producto.');
// //         } else {
// //             console.log('Stock actualizado correctamente. Datos actualizados:', updateData);
// //         }
// //     };
    

// //     const [acceptedPolicies, setAcceptedPolicies] = useState(true);

// //     const handleCheckboxChange = (e) => {
// //         setAcceptedPolicies(e.target.checked);
// //     };

    // const handleOrderSubmit = () => {
    //     if (cart.length === 0) {
    //         alert('No puedes realizar un pedido con un carrito vacío.');
    //         return; // Detener la ejecución si el carrito está vacío
    //     }

    //     if (acceptedPolicies && !loadingOrder) {
    //         setLoadingOrder(true); // Habilita el loader y desactiva el botón
    //         setTimeout(() => {
    //             setLoadingOrder(false); // Reactivar el botón después de 30 segundos
    //         }, 5000);
    //         saveOrderToDatabase(); // Llama a la función para guardar el pedido y actualizar el stock
    //     } else if (!acceptedPolicies) {
    //         alert('Debes aceptar las políticas de venta antes de realizar el pedido.');
    //     }
    // };

// //     if (!details || Object.keys(details).length === 0) {
// //         return (
// //             <div>
// //                 <h2>Error: No hay detalles del pedido</h2>
// //                 <button onClick={handleGoBack}>Regresar</button>
// //             </div>
// //         );
// //     }

// //     return (
// //         <section className='mt-20 mb-75'>
// //             <div className="container mtb-20">
// //                 <button className="btn btn-yellow" onClick={handleGoBack}>
// //                     Regresar
// //                 </button>
// //                 <div className='total-card bold color-grey'>
// //                     <div className='mb-16'>Subtotal</div>
// //                     <div className='txt-align-rigth'>${subtotal.toFixed(2)}</div>
// //                     <div className='mb-16'>Envio</div>
// //                     <div className='txt-align-rigth'>
// //                         {shippingCost === 0 ? 'Gratis' : `$${shippingCost.toFixed(2)}`}
// //                     </div>
// //                     <div className='color-black'>Total</div>
// //                     <div className='txt-align-rigth color-black'>${total.toFixed(2)}</div>
// //                 </div>
// //                 <div className='card-white'>
// //                     <h2 className='mb-16'>{methodTranslation[details.method] || details.method}</h2>
// //                     <div className='bold mb-16'>Nombre y apellido:</div>
// //                     <p className='mb-16'>{user?.user_metadata?.full_name || 'No disponible'}</p>
// //                     <div className='bold mb-16'>Teléfono de contacto:</div>
// //                     <p className='mb-16'>{user.phone || 'No disponible'}</p>

// //                     {/* Mostrar la fecha de entrega solo si el método no es 'national-shipping' */}
// //                     {details.method !== 'national-shipping' && (
// //                         <>
// //                             <div className='bold mb-16'>Fecha de entrega:</div>
// //                             <p className='mb-16'>{formattedDeliveryDate}</p>
// //                         </>
// //                     )}

// //                     {details.store && (
// //                         <>
// //                             <div className='bold mb-16'>Recoger en:</div>
// //                             <p className='mb-16'>{details.store}</p>
// //                         </>
// //                     )}
// //                     {details.address && (
// //                         <>
// //                             <div className='bold mb-16'>Dirección de entrega:</div>
// //                             <p className='mb-16'>{details.address}</p>
// //                         </>
// //                     )}

// //                     {/* Mostrar leyenda adicional si el método de envío es "national-shipping" */}
// //                     {details.method === 'national-shipping' && (
// //                         <div className="alert alert-info mtb-20">
// //                             <p className='mtb-16'><strong>Nota:</strong> Una vez pagado tu pedido, se enviará al siguiente día hábil de las 12:00p.m. </p>
// //                             <p>Días hábiles : Lunes a Jueves</p>
// //                         </div>
// //                     )}
// //                     {details.hour && (
// //                         <>
// //                             <div className='bold mb-16'>Horario de entrega:</div>
// //                             <p className='mb-16'>{details.hour}</p>
// //                         </>
// //                     )}
// //                 </div>
// //                 <ul>
// //                     {cart.map(item => (
// //                         <li className='card-white f-h mtb-16' key={item.id}>
// //                             <div className='item-image-wrapper'>
// //                                 <img className='item-image' src={item.image_url} alt={item.name} />
// //                             </div>
// //                             <div className='card-description'>
// //                                 <h3 className='mb-16'>{item.name}</h3>
// //                                 <p>Costo unitario: ${item.price}</p>
// //                                 <div className='f-h jc-sb bold mt-90'>
// //                                     Cantidad: {item.quantity}
// //                                     <p>${(item.quantity * item.price).toFixed(2)}</p>
// //                                 </div>
// //                             </div>
// //                         </li>
// //                     ))}
// //                 </ul>
// //                 <div className="policies-checkbox mtb-20">
// //                     <input
// //                         type="checkbox"
// //                         id="acceptPolicies"
// //                         checked={acceptedPolicies} // El checkbox estará marcado inicialmente
// //                         onChange={handleCheckboxChange}
// //                     />
// //                     <label htmlFor="acceptPolicies">
// //                         Acepto las <a href="/policies">políticas de venta</a>
// //                     </label>
// //                 </div>
// //                 <button
// //                     className='btn mtb-20'
// //                     onClick={handleOrderSubmit}
// //                     disabled={loadingOrder || !acceptedPolicies} // Desactivar cuando esté cargando o no haya aceptado políticas
// //                 >
// //                     {loadingOrder ? 'Procesando...' : 'Finalizar compra'}
// //                 </button>
// //             </div>
// //         </section>
// //     );
// // };

// // export default OrderSummary;

// import React, { useState } from 'react';
// import { useCart } from '../../context/cartContext';
// import { useAuth } from '../../context/authContext';
// import { supabase } from '../supabaseClient';
// import { useNavigate, useLocation } from 'react-router-dom';
// import moment from 'moment';
// import { Modal } from 'antd'; 
// import 'moment/locale/es'; // Importa el locale en español

// const OrderSummary = () => {
//     const { cart, clearCart } = useCart();
//     const { user } = useAuth();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const details = location.state?.details || {}; // Recupera los detalles del pedido

//     // Estados para el formulario
//     const [guestName, setGuestName] = useState(details.name || ''); // Inicializa con el valor de details.name
//     const [guestPhone, setGuestPhone] = useState(details.phone || ''); // Inicializa con el valor de details.phone
//     const [loadingOrder, setLoadingOrder] = useState(false);

//     // Traducciones para métodos de envío
//     const methodTranslation = {
//         'store-pickup': 'Recoger en tienda',
//         'foreign-shipping': 'Envío foráneo',
//         'package-shipping': 'Envío a domicilio',
//         'pickup-point': 'Entrega en punto fijo',
//         'national-shipping': 'Paquetería'
//     };

//     // Calcular subtotal y costos de envío
//     const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
//     const shippingCost = details.method === 'store-pickup'
//         ? 0
//         : details.method === 'foreign-shipping'
//         ? 0
//         : details.method === 'package-shipping'
//         ? 50
//         : details.method === 'national-shipping'
//         ? 350
//         : 0;
//     const total = subtotal + shippingCost;

//     // Formatear fecha de entrega
//     const formattedDeliveryDate = details.deliveryDate
//         ? moment(details.deliveryDate, 'YYYY-MM-DD').isValid()
//             ? moment(details.deliveryDate, 'YYYY-MM-DD').format(
//                 details.method === 'pickup-point'
//                     ? 'dddd, D [de] MMMM [de] YYYY [a las 12:00pm]'
//                     : 'dddd, D [de] MMMM [de] YYYY'
//             )
//             : 'Fecha inválida'
//         : null;

//     // Función para regresar al carrito
//     const handleGoBack = () => {
//         navigate('/cart');
//     };

//     // // Función para guardar el pedido en la base de datos
//     // const saveOrderToDatabase = async () => {
//     //     try {
//     //         if (!cart || cart.length === 0) {
//     //             throw new Error('El carrito está vacío. No se puede crear un pedido.');
//     //         }

//     //         if (total <= 0) {
//     //             throw new Error('El total del pedido es inválido.');
//     //         }

//     //         if (!details.method) {
//     //             throw new Error('No se ha seleccionado un método de entrega.');
//     //         }

//     //         const items = cart.map(item => ({
//     //             id: item.id,
//     //             name: item.name,
//     //             quantity: item.quantity,
//     //             price: item.price,
//     //             notes: item.notes || ''
//     //         }));

//     //         const deliveryDateForDB = moment(details.deliveryDate).format('YYYY-MM-DD');

//     //         const { data: orderData, error: orderError } = await supabase
//     //             .from('orders')
//     //             .insert([{
//     //                 user_id: user?.id || null,
//     //                 delivery_date: deliveryDateForDB,
//     //                 method: details.method,
//     //                 store: details.store,
//     //                 address: details.address,
//     //                 items: items,
//     //                 total: total,
//     //                 subtotal: subtotal,
//     //                 shipping_cost: shippingCost,
//     //                 hour: details.hour,
//     //                 print_status: false
//     //             }]);

//     //         if (orderError) throw orderError;

//     //         clearCart();
//     //         navigate('/order-placed', {
//     //             state: {
//     //                 orderDetails: {
//     //                     store: details.store,
//     //                     address: details.address,
//     //                     method: details.method
//     //                 },
//     //                 formattedDeliveryDate
//     //             },
//     //         });
//     //     } catch (error) {
//     //         console.error('Error al procesar el pedido:', error.message);
//     //         Modal.error({
//     //             title: 'Error al procesar el pedido',
//     //             content: error.message,
//     //             onOk: () => navigate(-1)
//     //         });
//     //     }
//     // };

//     // // Manejo del botón "Finalizar compra"
//     // const handleOrderSubmit = () => {
//     //     if (cart.length === 0) {
//     //         alert('No puedes realizar un pedido con un carrito vacío.');
//     //         return;
//     //     }

//     //     setLoadingOrder(true);
//     //     saveOrderToDatabase().finally(() => setLoadingOrder(false));
//     // };

//     const handleOrderSubmit = () => {
//         if (cart.length === 0) {
//             console.warn('El carrito está vacío. No se puede procesar la orden.');
//             alert('No puedes realizar un pedido con un carrito vacío.');
//             return;
//         }
    
//         console.log('Procesando el pedido...');
//         console.log('Detalles del pedido:', details);
//         console.log('Carrito:', cart);
    
//         setLoadingOrder(true); // Mostrar el estado de carga
    
//         saveOrderToDatabase()
//             .then(() => {
//                 console.log('Pedido guardado correctamente.');
//             })
//             .catch((error) => {
//                 console.error('Error al guardar el pedido:', error);
//             })
//             .finally(() => {
//                 setLoadingOrder(false); // Quitar el estado de carga
//             });
//     };
    
//     const saveOrderToDatabase = async () => {
//         try {
//             const userId = user?.id || null;
//             const items = cart.map(item => ({
//                 id: item.id,
//                 name: item.name,
//                 quantity: item.quantity,
//                 price: item.price,
//                 notes: item.notes || ''
//             }));
//             const deliveryDateForDB = moment(details.deliveryDate).format('YYYY-MM-DD');
    
//             // Generar folio manualmente si no existe
//             const folio = `FOLIO-${Date.now()}`;
    
//             const { data, error } = await supabase
//                 .from('orders')
//                 .insert([{
//                     user_id: userId,
//                     guest: guestName,
//                     guest_phone: sanitizedGuestPhone,
//                     delivery_date: deliveryDateForDB,
//                     method: details.method,
//                     store: details.store,
//                     address: details.address,
//                     items: items,
//                     total: Number(total),
//                     subtotal: Number(subtotal),
//                     shipping_cost: Number(shippingCost),
//                     hour: details.hour,
//                     folio,
//                     print_status: false
//                 }]);
    
//             if (error) throw error;
    
//             // Verifica que data no sea null
//             if (!data || data.length === 0) {
//                 throw new Error('El pedido se registró, pero no se recibieron detalles del pedido.');
//             }
    
//             // Navegar a la página de confirmación
//             navigate('/order-placed', {
//                 state: {
//                     orderDetails: {
//                         folio: data[0]?.folio || 'Sin folio',
//                         store: details.store,
//                         address: details.address,
//                         method: details.method,
//                         guest: guestName,
//                         guest_phone: sanitizedGuestPhone
//                     },
//                     formattedDeliveryDate
//                 }
//             });
    
//             clearCart(); // Limpia el carrito solo después de navegar correctamente
//         } catch (error) {
//             console.error('Error al procesar el pedido:', error.message);
//             Modal.error({
//                 title: 'Error al procesar el pedido',
//                 content: error.message
//             });
//         }
//     };

//     // Verifica si `details` está vacío o tiene problemas
//     if (!details || Object.keys(details).length === 0) {
//         console.error('Error: No hay detalles del pedido:', details);
//         return (
//             <div>
//                 <h2>Error: No hay detalles del pedido</h2>
//                 <button onClick={handleGoBack}>Regresar</button>
//             </div>
//         );
//     }

//     console.log('Detalles recibidos en OrderSummary:', details);
//     console.log('Nombre invitado:', guestName);
//     console.log('Teléfono invitado:', guestPhone);

//     return (
//         <section className='mt-20 mb-75'>
//             <div className="container mtb-20">
//                 <button className="btn btn-yellow" onClick={handleGoBack}>
//                     Regresar
//                 </button>
//                 <div className='total-card bold color-grey'>
//                     <div className='mb-16'>Subtotal</div>
//                     <div className='txt-align-right'>${subtotal.toFixed(2)}</div>
//                     <div className='mb-16'>Envío</div>
//                     <div className='txt-align-right'>
//                         {shippingCost === 0 ? 'Gratis' : `$${shippingCost.toFixed(2)}`}
//                     </div>
//                     <div className='color-black'>Total</div>
//                     <div className='txt-align-right color-black'>${total.toFixed(2)}</div>
//                 </div>
//                 <div className='card-white'>
//                     <h2 className='mb-16'>{methodTranslation[details.method] || details.method}</h2>
//                     <div className='bold mb-16'>Nombre y apellido:</div>
//                     <p className='mb-16'>{details.name || 'No disponible'}</p>
//                     <div className='bold mb-16'>Teléfono de contacto:</div>
//                     <p className='mb-16'>{details.phone || 'No disponible'}</p>

//                     {details.method !== 'national-shipping' && (
//                         <>
//                             <div className='bold mb-16'>Fecha de entrega:</div>
//                             <p className='mb-16'>{formattedDeliveryDate}</p>
//                         </>
//                     )}

//                     {details.store && (
//                         <>
//                             <div className='bold mb-16'>Recoger en:</div>
//                             <p className='mb-16'>{details.store}</p>
//                         </>
//                     )}
//                     {details.address && (
//                         <>
//                             <div className='bold mb-16'>Dirección de entrega:</div>
//                             <p className='mb-16'>{details.address}</p>
//                         </>
//                     )}
//                 </div>
//                 <ul>
//                     {cart.map(item => (
//                         <li className='card-white f-h mtb-16' key={item.id}>
//                             <div className='item-image-wrapper'>
//                                 <img className='item-image' src={item.image_url} alt={item.name} />
//                             </div>
//                             <div className='card-description'>
//                                 <h3 className='mb-16'>{item.name}</h3>
//                                 <p>Costo unitario: ${item.price}</p>
//                                 <div className='f-h jc-sb bold mt-90'>
//                                     Cantidad: {item.quantity}
//                                     <p>${(item.quantity * item.price).toFixed(2)}</p>
//                                 </div>
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//                 <button
//                     className='btn mtb-20'
//                     onClick={handleOrderSubmit}
//                     disabled={loadingOrder}
//                 >
//                     {loadingOrder ? 'Procesando...' : 'Finalizar compra'}
//                 </button>
//             </div>
//         </section>
//     );
// };

// export default OrderSummary;
import React, { useState } from 'react';
import { useCart } from '../../context/cartContext';
import { useAuth } from '../../context/authContext';
import { supabase } from '../supabaseClient';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'antd'; 
import 'moment/locale/es'; // Importa el locale en español

const OrderSummary = () => {
    const { cart, clearCart } = useCart();
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const details = location.state?.details || {}; // Recupera los detalles del pedido

    // Estados para el formulario
    const [guestName, setGuestName] = useState(details.name || ''); // Inicializa con el valor de details.name
    const [guestPhone, setGuestPhone] = useState(details.phone || ''); // Inicializa con el valor de details.phone
    const [loadingOrder, setLoadingOrder] = useState(false);

    // Traducciones para métodos de envío
    const methodTranslation = {
        'store-pickup': 'Recoger en tienda',
        'foreign-shipping': 'Envío foráneo',
        'package-shipping': 'Envío a domicilio',
        'pickup-point': 'Entrega en punto fijo',
        'national-shipping': 'Paquetería'
    };

    // Calcular subtotal y costos de envío
    const subtotal = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    const shippingCost = details.method === 'store-pickup'
        ? 0
        : details.method === 'foreign-shipping'
        ? 0
        : details.method === 'package-shipping'
        ? 50
        : details.method === 'national-shipping'
        ? 350
        : 0;
    const total = subtotal + shippingCost;

    // Formatear fecha de entrega
    const formattedDeliveryDate = details.deliveryDate
        ? moment(details.deliveryDate, 'YYYY-MM-DD').isValid()
            ? moment(details.deliveryDate, 'YYYY-MM-DD').format(
                details.method === 'pickup-point'
                    ? 'dddd, D [de] MMMM [de] YYYY [a las 12:00pm]'
                    : 'dddd, D [de] MMMM [de] YYYY'
            )
            : 'Fecha inválida'
        : null;

        // Función para regresar al carrito
        const handleGoBack = () => {
            navigate('/cart');
        };


    const handleOrderSubmit = () => {
        if (cart.length === 0) {
            alert('No puedes realizar un pedido con un carrito vacío.');
            return; // Detener la ejecución si el carrito está vacío
        }

        if (acceptedPolicies && !loadingOrder) {
            setLoadingOrder(true); // Habilita el loader y desactiva el botón
            setTimeout(() => {
                setLoadingOrder(false); // Reactivar el botón después de 30 segundos
            }, 5000);
            saveOrderToDatabase(); // Llama a la función para guardar el pedido y actualizar el stock
        } else if (!acceptedPolicies) {
            alert('Debes aceptar las políticas de venta antes de realizar el pedido.');
        }
    };

    const updateProductStock = async (productId, quantitySold) => {
                console.log(`Actualizando stock para el producto ID: ${productId} con cantidad vendida: ${quantitySold}`);
            
                const { data: product, error: productError } = await supabase
                    .from('products')
                    .select('pieces')
                    .eq('id', productId)
                    .single();
            
                if (productError) {
                    console.error('Error al obtener el stock:', productError);
                    throw new Error('Error al consultar el stock del producto.');
                }
            
                console.log('Producto obtenido:', product);
            
                const currentStock = Number(product.pieces);
                const soldQuantity = Number(quantitySold);
                const newStock = currentStock - soldQuantity;
            
                console.log(`Nuevo stock calculado: ${newStock}`);
            
                if (newStock < 0) {
                    throw new Error(`No hay suficiente stock para el producto ID: ${productId}.`);
                }
            
                const { data: updateData, error: updateError } = await supabase
                    .from('products')
                    .update({ pieces: newStock })
                    .eq('id', productId);
            
                if (updateError) {
                    console.error('Error al actualizar el stock:', updateError);
                    throw new Error('Error al actualizar el stock del producto.');
                } else {
                    console.log('Stock actualizado correctamente. Datos actualizados:', updateData);
                }
            };
            
        
            const [acceptedPolicies, setAcceptedPolicies] = useState(true);
        
            const handleCheckboxChange = (e) => {
                setAcceptedPolicies(e.target.checked);
            };
    
            const saveOrderToDatabase = async () => {
                try {
                    const userId = user?.id || null;
                    const items = cart.map(item => ({
                        id: item.id,
                        name: item.name,
                        quantity: item.quantity,
                        price: item.price,
                        notes: item.notes || ''
                    }));
                    const deliveryDateForDB = moment(details.deliveryDate).format('YYYY-MM-DD');
            
                    // Generar folio consecutivo
                    const generateFolio = async () => {
                        const { data: lastOrder, error: lastOrderError } = await supabase
                            .from('orders')
                            .select('folio')
                            .order('created_at', { ascending: false })
                            .limit(1);
            
                        if (lastOrderError) {
                            console.error('Error al obtener el último folio:', lastOrderError);
                            throw new Error('No se pudo generar el folio.');
                        }
            
                        let newFolioNumber;
                        if (lastOrder && lastOrder.length > 0) {
                            const lastFolio = lastOrder[0].folio.replace(/\D/g, '');
                            const lastFolioNumber = parseInt(lastFolio, 10);
            
                            if (isNaN(lastFolioNumber)) {
                                console.warn('El último folio no es un número válido. Iniciando desde 1.');
                                newFolioNumber = 1;
                            } else {
                                newFolioNumber = lastFolioNumber + 1;
                            }
                        } else {
                            console.warn('No se encontraron pedidos previos. Iniciando desde 1.');
                            newFolioNumber = 1;
                        }
            
                        return String(newFolioNumber).padStart(7, '0');
                    };
            
                    const folio = await generateFolio();
            
                    // Validar y actualizar stock
                    for (const item of items) {
                        const { data: product, error: fetchError } = await supabase
                            .from('products')
                            .select('id, pieces')
                            .eq('id', item.id)
                            .single();
            
                        if (fetchError) throw new Error(`Error al obtener el producto ${item.name}: ${fetchError.message}`);
                        if (!product) throw new Error(`El producto ${item.name} no existe en la base de datos.`);
            
                        if (product.pieces < item.quantity) {
                            throw new Error(`Stock insuficiente para ${item.name}. Disponibles: ${product.pieces}, requeridos: ${item.quantity}.`);
                        }
            
                        const newStock = product.pieces - item.quantity;
            
                        const { error: updateError } = await supabase
                            .from('products')
                            .update({ pieces: newStock })
                            .eq('id', item.id);
            
                        if (updateError) throw new Error(`Error al actualizar el stock de ${item.name}: ${updateError.message}`);
                    }
            
                    // Insertar el pedido
                    const { data, error } = await supabase
                        .from('orders')
                        .insert([{
                            user_id: userId,
                            guest: guestName,
                            guest_phone: guestPhone,
                            delivery_date: deliveryDateForDB,
                            method: details.method,
                            store: details.store,
                            address: details.address,
                            items: items,
                            total: Number(total),
                            subtotal: Number(subtotal),
                            shipping_cost: Number(shippingCost),
                            hour: details.hour,
                            folio,
                            print_status: false
                        }])
                        .select();
                    if (error) throw error;
            
                    if (!data || data.length === 0 || !data[0]?.folio) {
                        throw new Error('El pedido se registró, pero no se generaron detalles completos.');
                    }
            
                    // Navegar a la página de confirmación
                    navigate('/order-placed', {
                        state: {
                            orderDetails: {
                                folio: data[0]?.folio || 'Sin folio',
                                store: details.store,
                                address: details.address,
                                method: details.method,
                                guest: guestName,
                                guest_phone: guestPhone
                            },
                            formattedDeliveryDate
                        }
                    });
            
                    clearCart(); // Limpia el carrito solo después de navegar correctamente
                } catch (error) {
                    console.error('Error al procesar el pedido:', error.message);
                    Modal.error({
                        title: 'Error al procesar el pedido',
                        content: error.message
                    });
                }
            };

    // Verifica si `details` está vacío o tiene problemas
    if (!details || Object.keys(details).length === 0) {
        console.error('Error: No hay detalles del pedido:', details);
        return (
            <div>
                <h2>Error: No hay detalles del pedido</h2>
                <button onClick={handleGoBack}>Regresar</button>
            </div>
        );
    }

    // console.log('Detalles recibidos en OrderSummary:', details);
    // console.log('Nombre invitado:', guestName);
    // console.log('Teléfono invitado:', guestPhone);

    return (
        <section className='mt-20 mb-75'>
            <div className="container mtb-20">
                <button className="btn btn-yellow" onClick={handleGoBack}>
                    Regresar
                </button>
                <div className='total-card bold color-grey'>
                    <div className='mb-16'>Subtotal</div>
                    <div className='txt-align-right'>${subtotal.toFixed(2)}</div>
                    <div className='mb-16'>Envío</div>
                    <div className='txt-align-right'>
                        {shippingCost === 0 ? 'Gratis' : `$${shippingCost.toFixed(2)}`}
                    </div>
                    <div className='color-black'>Total</div>
                    <div className='txt-align-right color-black'>${total.toFixed(2)}</div>
                </div>
                <div className='card-white'>
                    <h2 className='mb-16'>{methodTranslation[details.method] || details.method}</h2>
                    <div className='bold mb-16'>Nombre y apellido:</div>
                    <p className='mb-16'>{details.name || 'No disponible'}</p>
                    <div className='bold mb-16'>Teléfono de contacto:</div>
                    <p className='mb-16'>{details.phone || 'No disponible'}</p>

                    {details.method !== 'national-shipping' && (
                        <>
                            <div className='bold mb-16'>Fecha de entrega:</div>
                            <p className='mb-16'>{formattedDeliveryDate}</p>
                        </>
                    )}

                    {details.store && (
                        <>
                            <div className='bold mb-16'>Recoger en:</div>
                            <p className='mb-16'>{details.store}</p>
                        </>
                    )}
                    {details.address && (
                        <>
                            <div className='bold mb-16'>Dirección de entrega:</div>
                            <p className='mb-16'>{details.address}</p>
                        </>
                    )}
                </div>
                <ul>
                    {cart.map(item => (
                        <li className='card-white f-h mtb-16' key={item.id}>
                            <div className='item-image-wrapper'>
                                <img className='item-image' src={item.image_url} alt={item.name} />
                            </div>
                            <div className='card-description'>
                                <h3 className='mb-16'>{item.name}</h3>
                                <p>Costo unitario: ${item.price}</p>
                                <div className='f-h jc-sb bold mt-90'>
                                    Cantidad: {item.quantity}
                                    <p>${(item.quantity * item.price).toFixed(2)}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="policies-checkbox mtb-20">
                     <input
                        type="checkbox"
                        id="acceptPolicies"
                        checked={acceptedPolicies} // El checkbox estará marcado inicialmente
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="acceptPolicies">
                        Acepto las <a href="/policies">políticas de venta</a>
                    </label>
                </div>
                <button
                    className='btn mtb-20'
                    onClick={handleOrderSubmit}
                    disabled={loadingOrder || !acceptedPolicies}
                >
                    {loadingOrder ? 'Procesando...' : 'Finalizar compra'}
                </button>
            </div>
        </section>
    );
};

export default OrderSummary;